<div class="flex flex-row max-sm:flex-col h-full">
  <app-links></app-links>
  <div class="flex flex-col w-full">
    <div class="relative">
      <div
        class="absolute z-10 inset-0 flex flex-row justify-between items-center text-3xl font-bold text-white mx-5"
      >
        <div>
          {{ constStore.contract().name }}
          <hr class="border-2 border-tertiary pr-64" />
        </div>
        <img
          class="h-28 w-auto rounded-md"
          [src]="'/api/image/' + constStore.contract().logo"
        />
      </div>
      <img
        [src]="'/api/image/' + constStore.contract().wallpaper"
        class="filter grayscale blur-xs md:w-full max-h-52 w-auto object-cover"
      />
      <!--<div class="md:w-full h-52 w-auto object-cover bg-gray-200"></div>-->
    </div>
    <app-news class="m-5"></app-news>
    <hr class="border-0.5 border-primary/25 mx-20" />
    <app-folders class="m-5"></app-folders>
    <hr class="border-0.5 border-primary/25 mx-20" />
  </div>
</div>
