import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { pipe, switchMap, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FileService, News, NewsService, UploadFile200Response } from '@api';
import { effect, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConstStore } from '@store/const.store';

export interface NewStateInterface {
  news: News[];
  total: number;
  searchFilter: string;
  colSort: string;
  sortOrder: 'asc' | 'desc' | '';
  page: number;
  pageSize: number;
  loadingNews: boolean;
}

export const NewsStore = signalStore(
  withState<NewStateInterface>({
    news: [],
    total: 0,
    searchFilter: '',
    colSort: 'name',
    sortOrder: 'asc',
    page: 1,
    pageSize: 10,
    loadingNews: false,
  }),
  withMethods(
    (
      store,
      constStore = inject(ConstStore),
      newsService = inject(NewsService),
      toastr = inject(ToastrService),
      fileService = inject(FileService)
    ) => ({
      addNews(n: News, file: Blob) {
        fileService
          .uploadImage(file)
          .subscribe((resp: UploadFile200Response) => {
            n.uuid = resp.uuid;
            newsService
              .saveNews(n)
              .subscribe(
                () =>
                  toastr.success(`L'actualité "${n.name}" a été créée`) &&
                  this.loadNews()
              );
          });
      },
      updateNews(n: News, file: Blob) {
        if (file) {
          fileService
            .uploadImage(file)
            .subscribe((resp: UploadFile200Response) => {
              n.uuid = resp.uuid;
              newsService
                .updateNews(n.id, n)
                .subscribe(
                  () =>
                    toastr.success(
                      `L'actualité "${n.name}" a été mise à jour`
                    ) && this.loadNews()
                );
            });
        } else {
          newsService
            .updateNews(n.id, n)
            .subscribe(
              () =>
                toastr.success(`L'actualité "${n.name}" a été mise à jour`) &&
                this.loadNews()
            );
        }
      },
      removeNew(news: News) {
        newsService
          .deleteNews(news.id)
          .subscribe(
            () =>
              toastr.success(`L'actualité "${news.name}" a été supprimée`) &&
              this.loadNews()
          );
      },
      pageEvent(
        colSort: string,
        sortOrder: 'asc' | 'desc' | '',
        page: number,
        pageSize: number
      ) {
        patchState(store, { colSort, sortOrder, page, pageSize });
        this.loadNews();
      },
      loadNews: rxMethod<void>(
        pipe(
          switchMap(() => {
            patchState(store, { loadingNews: true });
            return newsService
              .getNews(
                constStore.contract()?.id,
                store.colSort(),
                store.sortOrder(),
                store.page(),
                store.pageSize(),
                store.searchFilter()
              )
              .pipe(
                tap((page) => {
                  patchState(store, {
                    // @ts-ignore
                    news: page.results,
                    total: page.total,
                    loadingNews: false,
                  });
                })
              );
          })
        )
      ),
    })
  ),
  withHooks({
    onInit(store) {
      store.loadNews();
      const constStore = inject(ConstStore);
      effect(
        () => {
          const contract = constStore.contract();
          store.loadNews();
        },
        { allowSignalWrites: true }
      );
    },
  })
);
