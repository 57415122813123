@if (store.loadingDocument()) {
<div
  class="border-2 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col lg:justify-center"
>
  <div class="animate-pulse flex space-x-4">
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-700 rounded"></div>
      <div class="space-y-3">
        <div class="h-32 bg-slate-700 rounded col-span-2 w-44"></div>
      </div>
    </div>
  </div>
</div>
<div
  class="border-2 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col lg:justify-center"
>
  <div class="animate-pulse flex space-x-4">
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-700 rounded"></div>
      <div class="space-y-3">
        <div class="h-32 bg-slate-700 rounded col-span-2 w-44"></div>
      </div>
    </div>
  </div>
</div>
<div
  class="border-2 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col lg:justify-center"
>
  <div class="animate-pulse flex space-x-4">
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-700 rounded"></div>
      <div class="space-y-3">
        <div class="h-32 bg-slate-700 rounded col-span-2 w-44"></div>
      </div>
    </div>
  </div>
</div>
} @else { @for (element of store.documentList(); track element) {
<div
  (click)="handleClick(element)"
  (dblclick)="handleDoubleClick(element)"
  [matTooltip]="element.name"
  appTooltipTruncated
  class="justify-between w-52 border-4 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center lg:flex lg:flex-col"
  [ngClass]="
    selectedElement === element
      ? ' border-secondary font-bold'
      : 'border-white hover:border-secondary '
  "
>
  <p class="text-gray-800 break-words line-clamp-2 text-sm">
    {{ element.name | uppercase }}
  </p>
  <img
    class="max-w-24 mx-auto max-h-16 mt-4"
    [src]="
      element.objectType === 'folder' && element.uuid
        ? '/api/image/' + element.uuid
        : element.objectType === 'folder'
        ? '/icons/folder.svg'
        : '/icons/doc.svg'
    "
  />
</div>
}
<div class="flex flex-row w-full items-center justify-center">
  @if (store.page() !== 1) {
  <button class="text-secondary">
    <mat-icon
      fontIcon="keyboard_arrow_left"
      (click)="previousPage()"
      class="align-bottom mr-2"
    ></mat-icon>
  </button>
  }
  <div>
    {{
      (store.page() - 1) * store.pageSize() +
        1 +
        " - " +
        (store.total() > store.page() * store.pageSize()
          ? store.page() * store.pageSize()
          : store.total()) +
        " / " +
        store.total()
    }}
  </div>
  @if (store.total() > (store.page() * store.pageSize())) {
  <button class="text-secondary">
    <mat-icon
      fontIcon="keyboard_arrow_right"
      (click)="nextPage()"
      class="align-bottom ml-2"
    ></mat-icon>
  </button>
  }
</div>
}
