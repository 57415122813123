<div class="flex flex-col justify-between">
  <div class="flex flex-col gap-1 items-center text-2xl m-5">
    Créer un utilisateur
  </div>
  <mat-dialog-content>
    <div
      class="overflow-auto max-h-screen/2 bg-white shadow-sm rounded-md border-2 p-5"
    >
      <div class="flex flex-row gap-5 items-center mb-3">
        <span class="font-bold">Profils :</span>
        @for (profile of constStore.profileWithoutAdmin(); track profile) {
        <button
          class="p-1 border-2 border-secondary rounded-md"
          [ngClass]="
            selectedProfile?.id === profile.id
              ? 'bg-secondary text-white'
              : 'bg-white text-secondary'
          "
          (click)="changeProfile(profile)"
        >
          {{ profile.name }}
        </button>
        }
      </div>
      <form [formGroup]="form">
        <div class="m-5 flex flex-row gap-5 mb-3">
          <mat-form-field class="w-full">
            <mat-label>Prénom</mat-label>
            <input matInput type="text" formControlName="firstName" />
            @if(form.get('firstName')?.hasError('required')){
            <mat-error>Ce champs est obligatoire</mat-error>
            }
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Nom</mat-label>
            <input matInput type="text" formControlName="lastName" />
            @if(form.get('lastName')?.hasError('required')){
            <mat-error>Ce champs est obligatoire</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="m-5 flex flex-col flex-wrap gap-3 items-baseline">
          <mat-form-field class="w-full">
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" />
            @if(form.get('email')?.hasError('required')){
            <mat-error>Ce champs est obligatoire</mat-error>
            } @if(form.get('email')?.hasError('email')){
            <mat-error>Ce champs doit être une adresse mail</mat-error>
            }
          </mat-form-field>
          @if(selectedProfile?.ref!=='portal-admin'){
          <div class="w-full flex flex-row flex-wrap gap-3 items-start">
            <app-contract-autocomplete
              class="flex-1"
              [contractsControl]="form.get('contracts')"
              [all]="form.get('allContracts')?.value"
              [disabled]="form.get('allContracts')?.value"
            ></app-contract-autocomplete>
            <mat-checkbox
              class="pt-2"
              formControlName="allContracts"
              (change)="selectAll()"
            >
              Tous les contrats
            </mat-checkbox>
          </div>
          }
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <div class="flex flex-row gap-1 items-center justify-evenly m-5">
    <button
      class="rounded-2xl bg-gray-300 text-black px-5 py-2 w-fit hover:bg-gray-400"
      (click)="cancel()"
    >
      Annuler
    </button>
    <button
      class="rounded-2xl bg-secondary text-white disabled:bg-secondary/40 px-5 py-2 w-fit"
      [disabled]="!form.valid"
      (click)="validate()"
    >
      Valider
    </button>
  </div>
</div>
