import { Component, inject, OnInit } from '@angular/core';
import { NewsStore } from '@store/news.store';
import { DatePipe } from '@angular/common';
import { News, Rule } from '@api';
import { MatDialog } from '@angular/material/dialog';
import { NewsDetailDialogComponent } from '@app/community/news/news-detail-dialog/news-detail-dialog.component';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  protected newsDisplay?: boolean = true;
  store = inject(NewsStore);

  constructor(private dialog: MatDialog) {
    this.store.pageEvent('updateDate', 'desc', 1, 4);
  }

  open(news: News) {
    const dialogRef = this.dialog.open(NewsDetailDialogComponent, {
      minWidth: '50vw',
      data: news,
    });
  }
}
