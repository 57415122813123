import {patchState, signalStore, withHooks, withMethods, withState,} from '@ngrx/signals';
import {pipe, switchMap, tap} from 'rxjs';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {User, UsersService,} from '@api';
import {inject} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ConstStore} from '@store/const.store';

export interface UsersStateInterface {
  users: User[];
  total: number;
  searchFilter: string;
  colSort: string;
  sortOrder: 'asc' | 'desc' | '';
  page: number;
  pageSize: number;
  loadingUsers: boolean;
}

export const UsersStore = signalStore(
  withState<UsersStateInterface>({
    users: [],
    total: 0,
    searchFilter: '',
    colSort: '',
    sortOrder: '',
    page: 1,
    pageSize: 10,
    loadingUsers: true,
  }),
  withMethods(
    (
      store,
      constStore = inject(ConstStore),
      usersService = inject(UsersService),
      toastr = inject(ToastrService)
    ) => ({
      addUser(user: User) {
        usersService
          .saveUser(user)
          .subscribe(
            () =>
              toastr.success(
                `L'utlisateur ${user.firstName} ${user.lastName} a été créé`
              ) && this.loadUsers()
          );
      },
      updateUser(user: User) {
        usersService
          .updateUser(user.id, user)
          .subscribe(
            () =>
              toastr.success(
                `L'utilisateur ${user.firstName} ${user.lastName} a été mis à jour`
              ) && this.loadUsers()
          );
      },
      removeUser(user: User) {
        usersService
          .deleteUser(user.id)
          .subscribe(
            () =>
              toastr.success(
                `L'utilisateur ${user.firstName} ${user.lastName} a été supprimé`
              ) && this.loadUsers()
          );
      },
      synchronise() {
        usersService.synchronise().subscribe(
          () =>
            toastr.success(
              `Les utilisateurs ont été synchronisé`
            ) && this.loadUsers()
        );
      },
      pageEvent(
        colSort: string,
        sortOrder: 'asc' | 'desc' | '',
        page: number,
        pageSize: number
      ) {
        patchState(store, {colSort, sortOrder, page, pageSize});
        this.loadUsers();
      },
      loadUsers: rxMethod<void>(
        pipe(
          switchMap(() => {
            patchState(store, {loadingUsers: true});
            return usersService
              .getUsers(
                constStore.contract()?.id,
                store.colSort(),
                store.sortOrder(),
                store.page(),
                store.pageSize(),
                store.searchFilter()
              )
              .pipe(
                tap((page) => {
                  patchState(store, {
                    // @ts-ignore
                    users: page.results,
                    total: page.total,
                    loadingUsers: false,
                  });
                })
              );
          })
        )
      ),
    })
  ),
  withHooks({
    onInit(store) {
      store.loadUsers();
    },
  })
);
